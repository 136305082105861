import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

interface CurrencyState {
  currency: string;
  setCurrency: (currency: string) => void;
}

const initialState = {
  currency: `US$`,
};

export const useCurrencyStore = create<CurrencyState>()(
  persist(
    (set) => ({
      ...initialState,
      setCurrency: (item: string) => {
        set(() => ({
          currency: item,
        }));
      },
    }),
    {
      name: `currency-storage`,
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);
